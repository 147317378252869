footer {
    background-color: black;
    color: white;
    text-align: center;
    padding-top: 10px;
    bottom: 0;
    width: 100%;
}

.list {
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.list li {
    float: left;
}

.list li a {
    display: block;
    color: white;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
}